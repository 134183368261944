.search-icon{
    width: 22.38px;
    height: 22px;
}
.search-btn{
    width: 80px !important;
    height: 80px !important;
    background-color: #28B0A6;
    border: none;
}
.search-button-box{
   width: 100%;
   display: flex;
   justify-content: center;
   
    align-items: center;

}
.location-box {
    border-right: 0.5px solid grey; 
    padding: 10px;   
}
.activity-box{
     border-right: 0.5px solid grey;    
      padding: 10px;   
}
.date-box{
      padding: 10px;   
}
.location-box-heading{
font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 20px;
letter-spacing: 0.02em;
color: #212529;
}
.activity-box-heading {
font-family: 'Poppins';
font-weight: bold;
font-size: 20px;
letter-spacing: 0.02em;
color: #212529;
}
.date-box-heading{
font-family: 'Poppins';
font-weight: bold;
font-size: 20px;
letter-spacing: 0.02em;
color: #212529;
}
.ant-select-selection-search-input{
    border: none;
    border-width: 0px;
}
:where(.css-dev-only-do-not-override-m4timi).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector  {
    border: none;
    font-size: 13px;
    font-family: 'Poppins';
font-style: normal;
outline: none;
}
:where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined{
     border: none;
    font-size: 13px;
    font-family: 'Poppins';
font-style: normal;
outline: none;
}