.banner_container{
    width: 100%;
    height: 350px;
    background-image: url('../../images/desert_safari.webp');
    background-position: center;
    background-size: cover;
    background-size: 100% 100%;
    padding: 0px;
    margin: 0px;
}
.banner_overlay{
    width: 100% !important;
    height: 350px;
    background-color: #0000007d;
    background-repeat: repeat;
    position: absolute;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay_title{
    font-family: 'Poppins';
    color: #FFFFFF;
}
.desert_safari_main_container{
    width: 100%;
    min-height: 800px;
}
.desert_safari_main_col{
    width: 100%;
    min-height: 900px;
    
   
}
.desert_safari_inner_item{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
  
    margin-top: 40px;
   padding-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.desert_safari_inner_item:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item_image{
    height: 300px !important;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.item_image::before{
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.item_image:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

.item_package_name{
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 5px;
}
.item_package_title{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
}
.item_price_container{
    width: 90%;
    margin-left: 5%;
  
    height: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
   
}
.offer_price{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
}
.orignal_price{
      font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
   text-decoration-line: line-through;
    color: #FA3E2C;
    margin-left: 15px;
}
.item_review_container{
       width: 90%;
    margin-left: 5%;
    margin-bottom: 5px;
    height: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    
}
.review_text{
     font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 7px;
}
.item_itineary_container{
    width: 90%;
    margin-left: 5%;
   
}
.inner_itineary{
    width: 100%;
    height: 20px;
    margin-top: 2.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.item_btn_container{
    width: 90%;
    margin-left: 5%;
    height: 60px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.item_btn{
    width: 49%;
    height: 45px;
    font-family: 'Poppins';
    color: #FFFFFF;
    border-width: 0px;
    border-color: transparent;
     background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
}
.desert_safari_main_col_top_10{
    margin-top: 20px !important;
}