.calculator_container{
    width: 100%;
    height: auto;
    background-color: #FBFBFB;
    border-radius: 8px;
    margin-top: 50px;
    padding-top: 10px;
}
.price_container{
    width: 100%;
    height: 110px;
    padding: 12px;
}
.max_price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #FA3E2C;
}

.discounted_price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D293F;
}

.discounted_price_left{
      font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: #1D293F;
}

.per_person{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #546179;
}
.per_person_mobile{
      font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #546179;
    margin-left: 10px;
}

.off_percentage{
  
width: 101px;
height: 38px;
background: #28b0a784;
border-radius: 24px;
display: flex;
justify-content: center;
align-items: center;


}
.off_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #28B0A6;
}
.note_description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #99A3AD;
}
.form_container{
     width: 100%;
   padding: 12px;
}
.form_container_mobile{
       width: 100%;
   padding: 12px;
   margin-top: 80px;
}
.check_availability{
    width: 100%;
    height: 52px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    transition: 1s ease-in-out;
  
}
.check_availability:hover{
background: linear-gradient(90deg, #FA126C 9.15%, #FF9D4B 101.52%);
}
.modal_bg{
    width: 100%;
    height: 200px;
}
.modal_top{
    width: 100%;
    height: 240px;
    position: absolute;
    background-color: transparent;
    top: 50;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 10px;
}
.congratulation_heading{
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    font-family: 'Poppins';
}
.available_text{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
}
.modal_bg_image{
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
}