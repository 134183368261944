.login-container{
    min-height: 600px;
    margin-top: 80px;
}
.forgot_password{
    font-size: 14px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    color: #1D293F;
}
.btn-login{
      width: 100%;  
    height: 52px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    transition: 1s ease-in-out;
}
.register-now{
    width: 100%;
    font-size: 16px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    color: #1D293F;
    text-align: center;
    margin-top: 30px;
}