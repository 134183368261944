.help-container{
    height: 400px;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 10px;
}
.help-title-container{
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    
}
.help-title{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 60px;
/* identical to box height */
text-align: center;
letter-spacing: -0.64px;

color: #000000;


}
.help-description{

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;

/* identical to box height */
text-align: center;
letter-spacing: -0.38px;

/* Gray / Slate Gray */
color: #546179;


}

.help-for-sales{
    width: 100%;
    height: 300px;
    background-color: #28B0A6;
    border-radius: 10px;
    padding: 10px;
}
.help-for-support{
    width: 100%;
    height: 300px;
    background-color: #1D293F;
    border-radius: 10px;
    padding: 10px;
}
.help_icon{
    width: 60px;
    height: 60px;
    margin-top: 40px;
}
.help_text{
    font-size: 14px;
    font-family: 'Poppins';
    color: #FFFFFF;
    margin-top: 30px;
}