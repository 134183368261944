.ask_expert_text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.5px;

/* Gray / Slate Gray */
color: #546179;


}
.ask_expert_btn{
    box-sizing: border-box;
    width:100%;
    height: 54px;
    background: #FFFFFF;
    border: 2px solid #DCE5EB;
    border-radius: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #99A3AD;



}