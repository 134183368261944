.footer-main-container{
    min-height: 500px;
    margin-top: 100px;
}
.quick_links_container{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}
.add_icon{
    width: 30px;
    height: 30px;
}
.quick_link_text{

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 36px;
/* identical to box height, or 150% */
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;
margin-left: 20px;
margin-top: 15px;


}
.explore-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Gray / Slate Gray */
color: #546179;
margin-top: 5px;


}
.footer-header{
    border-bottom:1px solid #E9ECEF
}
.footer-primary-body{
    height: 120px;
    border-bottom:1px solid #E9ECEF;
    display: block;
    align-items: center;
    
}
.get-in-touch{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Light Sea Green */
color: #28B0A6;
}
.adventure-calling{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 60px;
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;


}
.primary-body-right{
   height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.globe-icon{
    width: 50px;
    height: 50px;
}
.address-section{
    margin-left: 10px;
}
.our-office-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

color: #000000;
}
.address-text{
 font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

color: #000000;
}
.arrow-right-address{
    width: 20px;
}
.footer-main-body{
    min-height: 300px;
   
}
.footer-main-body-contact-section{
    height: 300px;
  
    width: 100%;
    display: block;
    align-items: center;
}
.get-in-touch-body{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;
}
.lets-talk{
 font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 0px;
/* identical to box height */
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;
margin-top: 10px;
}
.lets-talk-arrow{
    width: 20px;
    height: 10px;
    margin-left: 20px;
}
.footer-links-title{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;
margin-top: 50px;
}
.footer_ul{
    list-style: none;
    text-align: left;
    list-style-position: inside;
    list-style-type:none;
    padding: 0px;
}
.footer-li-links{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 40px;
/* or 250% */
letter-spacing: -0.26px;

/* Gray / Slate Gray */
color: #546179;
text-decoration: none;


}
.footer-primary-bottom{
    height: 50px;
     border-bottom:1px solid #E9ECEF;
    margin-bottom: 10px;
}
.payment-image{
    width: 30%;
}