.container-cta{
    width: 100%;
    height: 350px !important;
    margin-top: 80px;
    background-image: url('../../images/my_cta.png') ;
    margin-bottom: 40px !important;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 10px;
    
    
 
}