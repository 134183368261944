.product-main-container{
    min-height: 800px;
}
.sticky_product{
    width: 100%;
    height: 100px;
    background-color: #FFFFFF;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.75);
box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.75);
  z-index: 1050 !important;     
}