.counter-container{
    height: 50px;
}
.counter-btn{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border:none;
     background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
     color: #ffffff;
     font-size: 16px;
}
.seat{
    font-size: 18px;
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
    text-align: center;
    display: flex;
    justify-content: center;
}