.success_container{
    height: 600px;
}
.process_message{
      color: #000000;
    font-family: 'Poppins';
    margin-top: 200px;
}
.spin{
    margin-left: 50%;
    margin-top: 30px;
}
.download_btn{
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    color: #FFFFFF;
    font-family: 'Poppins';
    padding: 10px;
    text-decoration: none;
    border-radius: 10px;
    margin-left: 45%;
    margin-top: 30px !important;
}