.subscribe-image{
    width: 100%;
}

.newsletter-container {
    margin-top: 20px;
    margin-top: 20px;
}
.newsletter-second-container{
   display: flex;
  align-items: center;
}

.inner-newsletter-container{
    height: 300px;
   width: 70%;
   

}
.subscribe-for-offer{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Light Sea Green */
color: #28B0A6;
}

.newsletter-description {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;
width: 60%;
/* Light Sea Green */
color: #546179;
line-height: 22px;
}

.inner-newsletter-heading{
    font-weight: bold;
    font-size: 25px;
    color: #1D293F;
    font-family: 'Poppins';
    font-weight: 600;
    width: 50%;
    line-height: 33px;

}
.email-box-container{
    width: 80%;
    height: 48px;
     display: inline-block;
    box-shadow: 0px 32px 84px rgba(14, 86, 124, 0.16);
  
  
}
.email-news-letter-container{
    width: 100%;
    height: 48px;
    background-color: #1D293F;
    display: flex;
    
  

}
.send-now-btn {
    width: 30%;
    height: 48px;
    border-radius: 0px;
    background-color: #28B0A6;


/* Poppins / 24 */
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
/* identical to box height */
text-align: center;
letter-spacing: -0.38px;

/* White */
color: #FFFFFF;


}
.antd-email-input{
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border-width: 0px;
   
}

