.cart-container{
    min-height: 600px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.cart_mainContainer{
    width: 100%;
    min-height: 190px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    border:1px solid #DCE5EB;
    background-color: #FFFFFF;
    margin-top: 0px !important;
}
.cart-product-img-holder{
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.cart-product-image_mobile{
  height: 180px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.cart-product-image{
    width: 98%;
    height: 120px;
    border-radius: 10px;
}

.cart-product-title{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
letter-spacing: -0.64px;

color: #000000;


}
.cart-product-normal{


font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
letter-spacing: -0.64px;
cursor: pointer;
color: #000000;


}
.cart-payment-section{
     width: 100%;
    height: 500px;
    border-radius: 8px;
    /* border:1px solid #DCE5EB; */
    background-color: #FFFFFF;
}
.coupon_container{
    width: 100%;
    height: 200px;
    border-radius: 10px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    margin-top: 10px;
    padding: 30px;
}
.coupon_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;

    color: #000000;
}
.coupon_input_holder{
    width: 80%;
    height: 50px;
    padding: 8px;
    border-radius: 10px;
    border:0.5px solid #c7c7c7;
    margin-top: 40px;
    margin-left: 10%;
    flex-direction: row;
    display: flex;
}
.coupon_input{
    width: 60%;
    height: 100%;
    border: none;
    font-family: 'Poppins';

}
.apply_coupon_button{
    width: 40%;
    height: 35px;
     background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
     border-color: none;
     color: #FFFFFF;
     font-family: 'Poppins';
}
.cart_total_container{
   
    height: 400px;
    border-radius: 10px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px -1px rgba(0,0,0,0.75);
    margin-top: 10px;
    padding: 30px;
    border-color: none;
    
}
.cart_total_div{
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 0.7px solid #c7c7c7;
}
.cart_total_text{
     font-family: 'Poppins';
     font-size: 16px;
}
.check_out_btn{
    width: 100%;
    height: 50px;
    border-radius: 50px;
    color: #FFFFFF;
    font-family: 'Poppins';
     background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
     margin-top: 30px;
}