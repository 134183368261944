.section_background{
    width: 100%;
    min-height: 550px;

    background-position: center;
   
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

.section_transparent{
    width: 100%;
    height: 550px;
    position: absolute;
    top:120px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
  
}
.breadcrum_parent_menu{
    color: #2ab0a7 !important;
    text-decoration: none;
    font-size: 16px;
     font-family: 'Poppins';
     font-weight: 500;
}
.breadcrum_active{
    color: #FFFFFF !important;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
}
.title-transparent{
    font-size: 45px;
    color: #FFFFFF;
      font-family: 'Poppins';
}