.parent-container{
  
   
    
}
.sliderHolder {
    width:100%;
    height: 550px;
   
}
.social-box{
    position: absolute;
    width: 100px;
    height: 550px;
    right: 0;
    top: 10;
}
.inner-container{
    
  width:100%;
  height: 100%;
   background: #0000004b;
   border-radius: 32px;
   position: relative;

}
.main-search-container{
    width: 80%;
    height: 100px;
    
    position: absolute;
    top: 620px;
    }

.search-body{
    width: 100%;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 43px 66px rgba(0, 0, 0, 0.07);
    padding: 10px;
    overflow: hidden;
}
.title-container{
    width: 40%;
    height: 50px;
    margin-top: 100px;
    margin-left:10%;
    display: flex;
    align-items: center;
}
.dot{
    width: 24px;
    height: 2px;
    background-color: #FFFFFF;
}
.slider-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        margin-left: 10px;
        height: 50px;
        display: flex;
        align-items: center;
}
.description-container{
     width: 90%;
    height: 100px;
    margin-top: 0px;
    margin-left:10%;
    display: flex;
    align-items: center;
}
.description-container-h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 78px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.weather-container {
    width: 10%;
    height: 50px;
    margin-top: 20px;
    margin-left:10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.weather-container-item {
    width: 50%;
    height: 100%;
}
.weather-container-item p{
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
}
.slider-btn-container {
    width: 6%;
    height: 50px;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.slider-btn-container button {
    
    background-color: transparent;
    border-color: transparent;
}
.bottom-right {
    width: 30%;
    height: 100px;
    position: absolute;
    right: 10px;
    display: flex;
}
.bottom-right p {
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
}
.circle{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #FFFFFF;
}
.search-body{
    margin-top: 100px;
}