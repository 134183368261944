.contact_main_container{
    padding: 0px;
    margin: 0px;
    position: relative;
 
}
.map_container{
    padding: 0px;
    margin: 0px;
}
.contact_box{
    width: 50%;
    height: 800px;
    background-color: #FFFFFF;
    position: absolute;
    top: 20%;
    left: 12.5%;
    box-shadow: 0px 4px 40px 0px rgba(43.000000000000014, 89.00000000000009, 255, 0.08);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    border-radius: 10px;
    
}
.gent_in_touch_container{
    width: 60%;
    margin-left: 15%;
   
}

.contact_title_main{
    font-size: 35px;
    font-weight: 600;
    font-family: 'Poppins';
}
.gent_in_touch_description{
      font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins';
}
.contact_section_left{
    height: 500px;
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 20px;
    border-right: 0.1px solid grey;
    
}
.list_container{
    width: 100%;
    height: 80px;
    margin-top: 10px;
}
.contact_icon_holder{
    width: 70px;
    height: 70px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    border-radius: 10px;
    box-shadow: 0px 4px 40px 0px rgba(43.000000000000014, 89.00000000000009, 255, 0.08);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_icon{
    width: 35px;
}
.contact_title{
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
}
.contact_description{
    font-size: 14px;
    
}
.contact_input{
    height: 50px;
     font-family: 'Poppins';
}
.contact_submit{
    width: 100%;
    height: 50px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    color: #FFFFFF;
     font-family: 'Poppins';
}
.contact_form_heading{
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins';
}
.contact_form_description{
    font-family: 'Poppins';
    font-size: 14px;
}