.overview-text{
 
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5px;
color: #1D293F;
}

.overview_options{
   font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;

display: flex;
align-items: center;
letter-spacing: 0.5px;

color: #546179;


}
.overview_innerbox{
    display: flex;
    margin-bottom: 20px;
}
.overview_inner_desc{
    font-family: 'Poppins';
    font-style: bold;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    color: #1D293F;
    margin-left: 10px;
}
.itineary-header{
    height: 85px;
    display: flex;
    align-items: center;
    
}
.itineary-header-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 48px;
        color: #1D293F;
}
.itineary-top-header{
    width: 100%;
    height: 50px;
    background-color: #FBFBFB;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.bubble_holder{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.bubble{
    width: 25px;
    height: 25px;
    background-color: #28B0A6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    color: #BCF0EC;
    font-size: 12px;
}

.itineary_main_title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
}
.itineary_title_holder{
    height: 50px;
    display: flex;
    align-items: center;
   cursor: pointer;
    
}
.arrow_holder {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_icon{
    width: 15px;
  
}
.inner-collapse{
    width: 90%;
    margin-left: 8.5%;
}
.itineary_sub_title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #212529;

}
.initneray_container_option{
    height: 130px;

   
   
}
.itineary_option{
    height: 120px;
    
    padding: 0px;
    margin-left: 5px;
    padding-right: 0px;
    padding-left: 0px;
}
.map_box{
    width: 100%;
    height: 100%;
    background-color: #F8F9FA;
    margin: 0px;
    padding: 0px;
    border-radius: 16px;
    cursor: pointer;
}
.map_box_anchor{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.view_on_map{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1D293F;
    margin-top: 10px;
    text-decoration: none;

}
.inclusion_header{
   font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
color: #1D293F;




}