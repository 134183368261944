.checkout_breadcrumb_section{
    width: 100%;
    height: 150px;
    background-image: url('../../images/checkout_bg.png');
    background-position:'center';
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;

    
}
.checkout_header_title{
    font-family: 'Poppins';
    font-style: bold;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
}
.checkout_main{
    min-height: 1600px;
    background-color: #FFFFFF;
    margin-top: 30px;
    padding: 35px;
    margin-bottom: 40px !important;
}

.coupon_container_checkout{
    width: 100%;
    min-height: 240px;
    background-color: #F9F9F9;
    border-radius: 12px;
    padding: 35px;
}
.checkout_coupon_text{
    font-size: 12px;
    margin-top: 20px;
     font-family: 'Poppins';
}
.container_checkout_item{
    width: 100%;
     min-height: 240px;
    background-color: #F9F9F9;
    border-radius: 12px;
    padding: 35px;
    margin-top: 20px;
}
.checkout-heading-title{
    color: #000000;
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: 700;
}
.checkout_header_login_section{
    margin-top: 20px;
}
.checkout_header_login_text{
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
}
.checkout_returning_customer{
     color: #000000;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
}
.checkout_login_text{
      color: #28B0A6;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    text-decoration: none;
}

.checkout_form_container{
    width: 100%;
    height: 200px;
    margin-top: 30px;

}
.checkout_btn{
    width: 50%;
    height: 45px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
     border-color: none;
     color: #FFFFFF;
     font-family: 'Poppins';
}