.testimonial-item-header{
    width: 100%;
    height: 60px;
    display: flex;
     align-items: center;
}
.testimonials-badge{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.testimonials-name{
    font-size: 16px;
     font-family: 'Poppins';
font-style: normal;
color: #546179;
margin-left: 10px;

justify-content: center;
margin-top: 15px;
}
.testimonials-title-holder{
    width: 100%;
    height: 30px;

}
.testimonilas-title{
    font-size: 16px;
    color: #1D293F;



/* Poppins / SemiBold / 18 */
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 111% */

/* Oxford Blue */
color: #1D293F;


    
}
.testimonials-review{
    line-height: 0px;
    font-family: 'Poppins';
    font-size: 14px;
    color: #1D293F;
}
.item-container-tetimonials{
    width: 95% !important;
    margin-left: 2.5% !important;
}