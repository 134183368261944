.product_detail_container{
    margin-top: 40px;
    height: 150px;
   
}
.breadcrum{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}
.breadCrumText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #99A3AD;
}
.product-name{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: #1D293F;
   

    width: 100%;

}
.total_review{
    font-size: 12px;
    color: #99A3AD;
    font-family: 'Poppins';
    font-style: normal;
    margin-left: 10px;

}
.product_rating_container{
    display: flex;
    align-items: center;
}