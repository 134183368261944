.category-container {
    margin-top: 80px;
    position: relative;
    
}
.category-container h1{
/* Select Category */



font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 60px;
letter-spacing: -0.64px;
color: #1D293F;


}
.category-container p {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 36px;
letter-spacing: -0.38px;
color: #546179;


}

.carousel-container {
    position: absolute;
    top: -100px;
}
.carousel-container_mobile{
      position: absolute;
    top: 0px !important;
}

.carousel-button-group{
    width: 110px;
    flex-direction: row;
    position: absolute;
    right: 0px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    
}
.enable{
    width: 50px;
    height: 50px;
    background-color: #28B0A6;
    border-radius: 50px;
    color: #FFFFFF;
}
.enable_mobile {
    width: 35px;
    height: 35px;
    background-color: #28B0A6;
    border-radius: 50px;
    color: #FFFFFF;
}
.disable{
    width: 50px;
    height: 50px;
    background-color: #28B0A6;
    border-radius: 50px;
    color: #FFFFFF;
}
.disable_mobile{
 width: 35px;
    height: 35px;
    background-color: #28B0A6;
    border-radius: 50px;
    color: #FFFFFF;
}
.item-container {
box-sizing: border-box;
width: 95%;
height: 223px;
left: 568px;
border: 1px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #DCE5EB;
box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.16);
border-radius: 8px;
padding: 20px;
cursor: pointer;
margin-left: 2.5%;

}
.item-bottom{
    width: 286px;
 
    position: absolute;
    bottom: 20px;
}
.item-icon{
    width: 40px;
    height: 40px;
}
.item-bottom h3{

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 36px;
/* identical to box height */
letter-spacing: -0.38px;

/* Oxford Blue */
color: #1D293F;


}
.item-bottom span {

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
/* identical to box height */
letter-spacing: 0.5px;

color: #99A3AD;
margin-top: -30px;


}
.num {
    width: 40px;
    height: 20px;
    background-color: #FEF2DE;
    border-radius: 10px;
    color: #F5A623;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
}